<template>
  <div class="checkbox-container">
    <input
      type="checkbox"
      class="input-cbx"
      :id="id"
      v-bind="$attrs"
      v-model="innerChecked"
      :value="value"
      :true-value="trueValue"
      :false-value="falseValue"
    >
    <label
      :for="id"
      class="checkbox">
      <span>
        <svg>
          <polyline points="1.5 6 4.5 9 12.5 1"></polyline>
        </svg>
      </span>
      <span class="text">
        <slot></slot>
        <em
          v-if="required"
          class="required-mark">*</em>
      </span>
    </label>
  </div>
</template>

<script>
/**
 * 기본 Checkbox 컴포넌트
 * @see https://vuejs.org/v2/guide/forms.html#Checkbox-1
 * @see https://github.com/creativetimofficial/vue-argon-design-system/blob/master/src/components/BaseCheckbox.vue
 * @see https://github.com/viljamis/vue-design-system/blob/master/src/elements/Input.vue
 */
export default {
  name: 'BaseCheckbox',
  /**
   * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
   */
  inheritAttrs: false,
  /**
   * https://vuejs.org/v2/api/#model
   */
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    /**
     * 선택 여부
     * @model
     */
    checked: {
      type: [Boolean, String, Number],
      default: false,
    },
    /**
     * 선택 값
     */
    value: {
      type: [Boolean, String, Number],
    },
    /**
     * 선택했을 때의 값
     */
    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    /**
     * 선택하지 않은 때의 값
     */
    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    /**
     * ID
     */
    id: {
      type: String,
      default() {
        // https://stackoverflow.com/a/12502559
        return `checkbox-${Math.random().toString(36).slice(2)}`;
      },
    },
    /**
     * 필수 여부
     */
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerChecked: {
      get() {
        return this.checked;
      },
      set(newVal) {
        this.onInput(newVal);
      },
    },
  },
  methods: {
    onInput(newVal) {
      /**
       * 선택 이벤트
       * @event change
       * @property {boolean} 선택 값
       */
      this.$emit('change', newVal);
    },
  },
};
</script>

<style scoped>
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```vue
<template>
  <div>
    <BaseCheckbox
      v-model="check0"
    >체크0</BaseCheckbox>

    <BaseCheckbox
      v-model="check1"
      trueValue="1"
      falseValue="0"
    >체크1(선택하면 "1", 선택하지 않으면 "0")</BaseCheckbox>

    <BaseCheckbox
      v-model="check2"
      :trueValue="9"
      :falseValue="5"
      required
    >(필수)체크2(선택하면 9, 선택하지 않으면 5)</BaseCheckbox>

    <BaseCheckbox
      v-model="check3"
      :trueValue="9"
      :falseValue="5"
      disabled
    >(비활성화)체크3(선택하면 9, 선택하지 않으면 5)</BaseCheckbox>

    <BaseCheckbox
      v-model="check4"
      :trueValue="9"
      :falseValue="5"
      disabled
    >(선택, 비활성화)체크4(선택하면 9, 선택하지 않으면 5)</BaseCheckbox>
  </div>
</template>

<script>
export default {
  data() {
    return {
      check0: false,
      check1: '1',
      check2: 5,
      check3: 5,
      check4: 9,
    };
  },
}
</script>
```
</docs>

<template>
  <div class="radio-container">
    <label
      :for="id"
    >
      <input
        type="radio"
        :id="id"
        v-bind="$attrs"
        v-model="innerCheckedValue"
        :value="value"
      >
      <span class="radio-bg"></span>
      <span class="text">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script>
/**
 * 기본 RadioButton 컴포넌트
 * https://vuejs.org/v2/guide/forms.html#Radio-1
 * https://github.com/creativetimofficial/vue-argon-design-system/blob/master/src/components/BaseRadio.vue
 */
export default {
  name: 'BaseRadioButton',
  /**
   * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
   */
  inheritAttrs: false,
  /**
   * https://vuejs.org/v2/api/#model
   */
  model: {
    prop: 'checkedValue',
    event: 'change',
  },
  props: {
    /**
     * 선택된 값
     * @model
     */
    checkedValue: {
      type: [String, Number, Boolean],
    },
    /**
     * 선택 값
     */
    value: {
      type: [String, Number, Boolean],
    },
    /**
     * ID
     */
    id: {
      type: String,
      default() {
        // https://stackoverflow.com/a/12502559
        return `radio-${Math.random().toString(36).slice(2)}`;
      },
    },
  },
  computed: {
    innerCheckedValue: {
      get() {
        return this.checkedValue;
      },
      set(newVal) {
        this.onInput(newVal);
      },
    },
  },
  methods: {
    onInput(newVal) {
      /**
       * 선택 이벤트
       * @event change
       * @property {string|number} 선택된 값
       */
      this.$emit('change', newVal);
    },
  },
};
</script>

<style scoped>
  .radio-container {display:inline-block; margin-right:80px;}
  .radio-container:last-of-type {margin-right:0;}
  .radio-container > label {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: nowrap;
    margin: 6px 0;
    cursor: pointer;
    position: relative;
  }
  .input-container.multiple .radio-container label {
    margin: 6px 0 !important;
  }
  .radio-container input{
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  .radio-bg {
    display:inline-block;
    width: 20px;
    height: 20px;
    background:#eaeaea;
    /*border: 1px solid #111;*/
    border-radius: 100%;
    margin-right: 10px;
    position: relative;
  }
  .radio-bg::before, .radio-bg::after {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    position: absolute;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  .radio-bg:before {
    background: #111;
    opacity: 0;
    -webkit-transition: .3s;
    transition: .3s;
    /*margin:-1px;*/
  }
  input:checked+.radio-bg::before {
    opacity: 1;
    -webkit-transform: scale(.4);
    transform: scale(.4);
  }
  .radio-bg::after {
    background: #aaa;
    opacity: .4;
    -webkit-transition: .6s;
    transition: .6s;
  }
  .radio-container .text {
    font-size: 14px;
    font-weight: 500;
    color: #666;
  }
  .radio-container input:checked+.radio-bg::before {
    opacity: 1;
    transform: scale(.4);
  }
  .radio-container input:checked ~ .text {
    color:#111;
  }
  .radio-container input:hover+.radio-bg:before,
  .radio-container input:focus+.radio-bg:before {
    background: #111;
  }
  .radio-container input:hover~.text {
    color: #111;
  }
  .radio-container input:focus+.radio-bg::after,
  .radio-container input:active+.radio-bg::after {
    opacity: .1;
    transform: scale(1.8);
  }
  /* disabled */
  .radio-container input:disabled ~ .text {
    color:#ddd;
  }
  .radio-container input:checked:disabled+.radio-bg::before,
  .radio-container input:focus:disabled+.radio-bg::before {
    opacity: 1;
    transform: scale(.4);
    background-color:#c8c8c8;
  }
  .radio-container input:disabled:hover+.radio-bg:before,
  .radio-container input:disabled:focus+.radio-bg:before {
    background: #c8c8c8;
  }
  .radio-container input:disabled:focus+.radio-bg::after,
  .radio-container input:disabled:active+.radio-bg::after {
    opacity: 0;
    transform: scale(0);
  }
  .radio-container input:disabled+.radio-bg::before {
    transform: scale(0);
  }
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<BaseRadioButton name="radio" :checkedValue="2" :value="1">라디오버튼1</BaseRadioButton>
<BaseRadioButton name="radio" :checkedValue="2" :value="2">라디오버튼2(선택)</BaseRadioButton>
<BaseRadioButton name="radio1" :checkedValue="2" :value="1" disabled>라디오버튼3(비활성화)</BaseRadioButton>
<BaseRadioButton name="radio1" :checkedValue="2" :value="2" disabled>라디오버튼4(선택, 비활성화)</BaseRadioButton>
```
</docs>

<template>
  <a
    href="javascript:void(0);"
    class="back"
    @click="onClick"
  >{{ $t('content.common.button.btn21') }}</a>
</template>

<script>
/**
 * Back Button 컴포넌트
 */
export default {
  name: 'BackButton',
  methods: {
    onClick(event) {
      /**
       * 클릭 이벤트
       * @event click
       * @property {Event} 이벤트 https://developer.mozilla.org/ko/docs/Web/API/Element/click_event
       */
      this.$emit('click', event);
    },
  },
};
</script>


<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<BackButton
  @click="showAlert('뒤로 돌아갑니다.')"
/>
```
</docs>

<template>
  <div>
    <!-- 레이블 -->
    <div
      v-if="label"
      class="input-title"
    >
      {{label}}
    </div>
    <div
      class="input-file-container"
      :class="containerClasses"
    >
      <!-- 선택한 파일명 input -->
      <input
        type="text"
        class="upload-name"
        readonly="readonly"
        v-bind="$attrs"
        :value="value"
      >
      <!-- 삭제 버튼 -->
      <button
        type="button"
        v-if="value"
        class="btn-del"
        @click="onFileRemove"
      ><span>{{$t('content.common.button.btn07')}}</span></button>
      <!-- 버튼 레이블 -->
      <label
        v-if="buttonLabel"
        :for="id"
      >
        {{buttonLabel}}
      </label>
      <!-- 파일 input -->
      <input
        ref="fileInput"
        type="file"
        :id="id"
        class="upload-hidden"
        @change="onFileChange"
        :accept="accept"
      >
    </div>
  </div>
</template>

<script>
/**
 * 파일 Input 컴포넌트
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
 */
export default {
  name: 'FileInput',

  /**
   * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
   */
  inheritAttrs: false,

  props: {
    /**
     * 파일명
     * @model
     */
    value: {
      type: String,
    },

    /**
     * 레이블
     */
    label: {
      type: String,
    },

    /**
     * 버튼 레이블
     */
    buttonLabel: {
      type: String,
      default: '찾아보기',
    },

    /**
     * ID
     */
    id: {
      type: String,
      default() {
        // https://stackoverflow.com/a/12502559
        return `input-${Math.random().toString(36).slice(2)}`;
      },
    },

    /**
     * 파일유형 제한
     */
    accept: {
      type: String,
      default: '.hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf'
    },

    /**
     * 유효성 검사 실패 여부
     */
    invalid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fileData: null,
    };
  },

  computed: {
    containerClasses() {
      return {
        error: this.invalid,
      };
    },
  },

  methods: {
    /**
     * 파일 선택
     */
    onFileChange(event) {
      const fileData = event.target.files[0];
      this.fileData = fileData;

      /**
       * 파일 선택 이벤트
       * @event change
       * @property {File} 선택한 파일 https://developer.mozilla.org/en-US/docs/Web/API/File
       */
      this.$emit('change', fileData, this.id);
    },

    /**
     * 파일 삭제
     */
    onFileRemove() {
      /**
       * 파일 삭제 이벤트
       * @event remove
       * @property {File} 선택한 파일 https://developer.mozilla.org/en-US/docs/Web/API/File
       */
      this.$emit('remove', this.fileData, this.id);

      this.$refs.fileInput.value = '';

      this.fileData = null;
    },
  },
};
</script>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<FileInput id="file-name-1" />

<br />

<FileInput
  id="file-name-2"
  label="파일첨부"
  placeholder="파일을 첨부해주세요"
  buttonLabel="파일 선택"
  @change="(file) => showAlert(`${file.name}을 선택하였습니다.`)"
/>

<br />

<FileInput id="file-name-3" invalid />
<span class="input-error-desc">첨부할 파일을 선택해 주세요.</span>
```
</docs>
